<script setup lang="ts">
import type { Product, ProductBadge } from 'types/models/product'
import type { AddToCartSources } from '~/types/gtm'
import { useMediaQuery } from '@vueuse/core'
import { parseMeta, productUrl } from 'lib/routing'
import { sortOrder } from 'utils/array/sortOrder'
import { breakpointsConfig } from '~/utils/css/breakpoints'
import ProductPriceDisplay from './ProductPriceDisplay.vue'

/**
 * product-grid-large = default, show all info
 * product-grid-small = image only + quickbuy on click rather than linking you to PDP
 */
export type GridType = 'product-grid-large' | 'product-grid-small' | string

interface Props {
  product: Product
  isRelated?: boolean
  slug?: string
  content?: boolean
  collectionIndex?: number
  lazy?: boolean
  hasQuickShop?: boolean
  gridType?: GridType
  showColorIndicator?: boolean
  pageSource?: AddToCartSources
}

const props = withDefaults(defineProps<Props>(), {
  isRelated: false,
  slug: '',
  content: false,
  collectionIndex: 0,
  lazy: true,
  hasQuickShop: false,
  gridType: 'product-grid-large',
  showColorIndicator: true,
  pageSource: 'otherPage',
})
const emit = defineEmits<{
  (e: 'productClick', product: Product): void
}>()
const gtm = useGTM()
const route = useRoute()
const speechBubbleStore = useSpeechBubbleStore()
const quickShopStatus = useQuickShopStatus()

const isSmallScreen = useMediaQuery(`(max-width: ${breakpointsConfig.phablet - 1}px)`)

const { product } = toRefs(props)
const hero = computed(() => product.value.media.hero)
const titleId = computed(() => `${props.product.sku}-${props.slug}-title`)
const { isCheckoutPage, isProductPage } = parseMeta(route)
const selectedImage = hero.value.small || hero.value.thumbnail || hero.value?.original
const alternativeImage = computed(() => product.value.media.alternate || undefined)

const showObserver = !(isCheckoutPage || (isProductPage && !props.isRelated))
const quickShopState = ref<'uninitialized' | 'visible' | 'hidden'>('uninitialized')
const delayedQuickShopState = ref<'visible' | 'hidden'>('hidden')
const productCartState = ref<'focused' | 'unfocused'>('unfocused')

const { heroBadge, smallBadges } = useBadgeClassification(props.product.badges)

if (!selectedImage)
  console.error(`Missing image for: ${props.product.sku} ${props.product.name}`)

const image = {
  url: selectedImage?.url || `${process.env.baseUrlClient}/images/socks.svg`,
  label: selectedImage?.label || '🧦',
}

interface color {
  color: string
  id: string
}

const colorIndicatorRelatedColors = computed<color[]>(() => {
  const currentColor = props.product.color.primary
  const currentSku = props.product.sku
  const relatedColors = props.product.relatedColors

  if (!currentColor)
    return relatedColors

  return [{
    color: currentColor,
    id: currentSku,
  }, ...relatedColors]
})

function onProductClick(e: Event) {
  // When small grid and small screen, show quick shop instead of navigating to PDP
  if (props.hasQuickShop && props.gridType === 'product-grid-small' && isSmallScreen.value) {
    e.preventDefault()
    showQuickShop()
    return
  }

  if (props.isRelated) {
    gtm.pushProductPageEvent({
      action: 'similarProduct',
      label: props.product.name,
      sku: props.product.sku,
    })
  }

  gtm.pushProductClick({
    product: props.product,
    collectionContent: props.content,
    collectionIndex: props.collectionIndex,
    listInfo: props.isRelated ? `Related products for: ${props.product.name}` : '',
  })

  emit('productClick', props.product)
}

function intersected() {
  gtm.pushProductImpression({
    product: props.product,
    collectionContent: props.content,
    collectionIndex: props.collectionIndex,
    listInfo: props.isRelated ? `Related products for: ${props.product.name}` : '',
  })
}

function showQuickShop() {
  quickShopStatus.value = 'visible'
  quickShopState.value = 'visible'
  setTimeout(() => {
    delayedQuickShopState.value = 'visible'
  }, 400)
  if (isSmallScreen.value)
    document.body?.classList.add('lock-scroll')
}

function hideQuickShop() {
  quickShopStatus.value = 'visible'
  quickShopState.value = 'hidden'
  delayedQuickShopState.value = 'hidden'
  if (isSmallScreen.value)
    document.body?.classList.remove('lock-scroll')
}

onBeforeUnmount(() => {
  document.body?.classList.remove('lock-scroll')
})

/* Inline composable to classify badges to show in the product card */
function useBadgeClassification(productBadges: ProductBadge[]) {
  const heroBadgesList = ['buy-with-prime', 'multi-buy', 'interpride', 'last-chance', 'special-edition', 'version-2', 'gift-idea', 'new']
  const smallBadgesList = ['low-stock', 'bestsellers', 'organic-cotton']

  const classifyBadges = (includedBadgeNames: string[]) => {
    return productBadges.filter(badge => includedBadgeNames.includes(badge.name))
  }

  const heroBadges = computed(() => classifyBadges(heroBadgesList))
  const smallBadges = computed(() => classifyBadges(smallBadgesList))

  const heroBadge = computed(() => sortOrder(heroBadges.value, heroBadgesList)[0])
  const smallBadgesOrdered = computed(() => sortOrder(smallBadges.value, smallBadgesList))

  return {
    heroBadge,
    smallBadges: smallBadgesOrdered,
  }
}

function focusCartBtn() {
  productCartState.value = 'focused'
}

function unfocusCartBtn() {
  productCartState.value = 'unfocused'
}
</script>

<template>
  <div
    class="product-card__container"
    :class="[
      {
        'overflow--clip': delayedQuickShopState === 'hidden',
        'overflow--visible': delayedQuickShopState === 'visible',
      },
    ]"
    data-test="product-card-container"
  >
    <div
      class="product-card"
      :class="[{ 'sold-out': product.stockStatus === 'no-stock' }]"
      :aria-labelledby="titleId"
    >
      <article
        :data-sku="product.sku"
        :aria-labelledby="titleId"
        data-test="collection-product"
        @click="onProductClick"
      >
        <div class="badge-container">
          <ProductBadgeHero
            v-if="heroBadge && gridType !== 'product-grid-small'"
            :badge="heroBadge"
          />
        </div>
        <NuxtLink
          :external="product.categories.includes('buy-with-prime')"
          :to="{
            path: productUrl(product.sku),
            query: { ...route.query },
          }"
          class="image-wrapper"
          @click="speechBubbleStore.hideSpeechBubble()"
        >
          <ProductCardImage
            :image="image"
            :image-alternative="alternativeImage"
            :lazy="lazy"
          />
        </NuxtLink>

        <div
          :id="titleId"
          class="details"
          :class="[{ hidden: gridType === 'product-grid-small' }]"
        >
          <div class="details__wrapper">
            <ProductColorIndicatorList
              v-if="showColorIndicator"
              :products="colorIndicatorRelatedColors"
              class="colors"
            />
            <NuxtLink
              :external="product.categories.includes('buy-with-prime')"
              class="title"
              data-test="collection-product-title"
              :to="{
                path: productUrl(product.sku),
                query: { ...route.query },
              }"
              @click="speechBubbleStore.hideSpeechBubble()"
            >
              {{ product.name }}
            </NuxtLink>

            <div data-test="collection-product-price">
              <ProductPriceDisplay :product="product" />
            </div>
            <div class="bottom-container">
              <ProductBadgeList
                v-if="smallBadges.length > 0"
                :badges="smallBadges"
              />
              <button
                v-if="hasQuickShop"
                class="cart"
                :class="[{ focused: productCartState === 'focused' }]"
                @click.prevent="showQuickShop"
                @focus="focusCartBtn"
                @blur="unfocusCartBtn"
              >
                <span
                  class="cart__icon"
                  :aria-label="$t('cart')"
                />
              </button>
            </div>
          </div>

          <Observer
            v-if="showObserver"
            :options="{ threshold: 0.5 }"
            :run-once="true"
            @intersect="intersected"
          />
          <!-- keep sold-out indicator? -->
          <ProductSoldOutIndicator
            v-if="product.stockStatus === 'no-stock'"
            class="sold-out-tape"
          />
        </div>
      </article>
    </div>

    <ClientOnly>
      <Teleport
        to="body"
        :disabled="!isSmallScreen"
      >
        <ProductQuickShop
          v-if="hasQuickShop"
          :quick-shop-state="quickShopState"
          :product="product"
          :image="image"
          :lazy="lazy"
          :show-pdp-link="gridType === 'product-grid-small' && isSmallScreen"
          :page-source="pageSource"
          @close="hideQuickShop"
        />
      </Teleport>
    </ClientOnly>
  </div>
</template>

<style lang="scss" scoped>
@import 'assets/scss/rules/breakpoints';
@import 'assets/scss/typography/body';

.product-card {
  height: 100%;
  display: grid;
  position: relative;
  background: var(--white);
  &:nth-of-type(4n) {
    border-right: none;
  }
  &__container {
    position: relative;
  }
}

article {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}

.badge-container {
  position: relative;
  top: 10px;
  left: 7px;
  z-index: 1;

  @media (min-width: $tablet) {
    top: 15px;
  }
}

.details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1.4rem 2.4rem;
  flex: 1;
  @media (min-width: $tablet) {
    padding: 0 2.4rem 2.4rem;
  }
  &__wrapper {
    width: 100%;
    padding-bottom: 2rem;
    @media (min-width: $phablet) {
    padding-bottom: unset;
    }
  }
}

.title {
  @include body1;
  text-wrap: wrap;
}

.title:hover {
  text-decoration: underline;
}

.bottom-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 4rem;

  .cart {
    background-color: var(--green);
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 auto;
    opacity: 1;

    &__icon {
      mask: url('/icons/cart.svg') no-repeat center / contain;
      width: 1.8rem;
      height: 1.8rem;
      background-color: var(--black);
      display: inline-block;
      opacity: 1;
    }
    @media (min-width: calc($laptop + 1px)) {
      opacity:0;
      &.focused {
        display: inline-flex;
        opacity: 1;
        .cart__icon {
        display: inline-block;
          opacity: 1;
        }
      }
      &__icon {
        opacity: 0;
      }
    }
  }
}

.upsell-carousel .bottom-container {
  margin-top: -1rem;
}

.product-card {
  @media (min-width: calc($laptop + 1px)) {
    &:hover {
      .cart {
        opacity: 1;
        &__icon {
          opacity: 1;
        }
      }
    }
  }
}

.sold-out-tape {
  top: 7rem;
  left: -2rem;
  position: absolute;
  transform: rotate(15deg);
  width: calc(100% + 6rem);
  left: clamp(-2rem, 10vw, -3rem);

  @media (min-width: $phone) {
    top: 12rem;
  }
}

.overflow {
  &--clip {
    overflow: clip;
  }
  &--visible {
    overflow: visible;
  }
}
.hidden {
  display: none;
}

.colors {
  margin-bottom: 2rem;
  margin-top: 1rem;
}

.image-wrapper {
  border: 2px solid transparent;
  &:focus-visible {
    border: 2px solid var(--black);
    border-radius: 2px;
    outline: none;
  }
}
</style>
